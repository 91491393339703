<template>
  <!-- Show patient picture -->
  <div>
    <div
      v-if="picture != '' && (type === 'MR' || type === 'Other' || type === 'IPD')"
      v-viewer="options"
      class="previewHeight"
    >
      <!-- MR -->
      <b-row v-if="type === 'MR'">
        <template v-for="thumbnail in picture">
          <b-img
            :key="thumbnail"
            v-bind="mrImgConfig"
            :src="thumbnail"
            fluid
            thumbnail
            alt=" "
            viewer
          >
            />
          </b-img>
        </template>
      </b-row>
      <!-- IPD -->
      <b-row v-else-if="type === 'IPD'">
        <template v-for="(thumbnail, index) in picture">
          <b-img
            :key="thumbnail.ipdPic"
            v-bind="imgConfig"
            :src="thumbnail.ipdPic"
            fluid
            thumbnail
            alt=" "
            viewer
          >
            />
          </b-img>
          <b-form-checkbox
            :key="index"
            v-model="ipdSelected"
            :value="{date, type, location: date, nameImage: thumbnail.nameImage}"
            @change="checkItemIPD()"
          />
        </template>
      </b-row>
      <!-- Other -->
      <b-row v-else-if="type === 'Other'">
        <template v-for="(thumbnail, index) in picture">
          <b-img
            :key="thumbnail"
            v-bind="imgConfig"
            :src="thumbnail"
            fluid
            thumbnail
            alt=" "
            viewer
          >
            />
          </b-img>
          <b-form-checkbox
            :key="index + '1'"
            v-model="otherSelected"
            :value="{date, type, group, nameImage: picGroup[index].imageBase64}"
            plain
            @change="checkItemOther()"
          />
        </template>
      </b-row>
      <!-- EMR -->
      <b-row v-else>
      <!-- <general />
      <b-form-checkbox
        :key="txn"
        v-model="emrSelected"
        :value="{date, type, index: index + offSet, location: date, nameImage: ''}"
      /> -->
      </b-row>
      <b-pagination
        v-if="type === 'IPD'"
        v-model="currentPage"
        :total-rows="ipdPagination"
        :per-page="perPage"
        align="left"
        class="mt-2"
      >
        <template
          #first-text
        >
          <span class="text-success">First</span>
        </template>
        <template #prev-text>
          <span class="text-danger">Prev</span>
        </template>
        <template #next-text>
          <span class="text-warning">Next</span>
        </template>
        <template #last-text>
          <span class="text-info">Last</span>
        </template>
        <template #ellipsis-text>
          <div>
            <b-spinner
              small
              type="grow"
            />
            <b-spinner
              small
              type="grow"
            />
          </div>
        </template>
        <template #page="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </template>
      </b-pagination>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        size="sm"
        @click="selectAll(type)"
      >
        เลือกทั้งหมด
      </b-button>
    </div>
    <div v-if="type === 'EMR'">
      <b-row>
        <b-col
          v-if="emrLocation == 'general'"
          cols="12"
        >
          <general
            :txn="txn"
          />
        </b-col>
        <b-col
          v-else-if="emrLocation == 'checkUp'"
          cols="12"
        >
          <checkUp
            :txn="txn"
          />
        </b-col>
        <!-- <b-col
          v-else-if="emrLocation == 'checkUp'"
          cols="12"
        >
          <checkUp
            :txn="txn"
          />
        </b-col> -->
        <b-col
          v-else-if="emrLocation == 'obg'"
          cols="12"
        >
          <obg
            :txn="txn"
          />
        </b-col>
      </b-row>
    </div>
  </div>
  <!-- Show patient picture -->
</template>

<script>
import VueViewer from 'v-viewer'
import Vue from 'vue'
import general from '@/components/record-viewer/general.vue'
import checkUp from '@/components/record-viewer/checkup.vue'
import obg from '@/components/record-viewer/ob.vue'
import 'viewerjs/dist/viewer.css'
import { BFormCheckbox, BPagination, BSpinner } from 'bootstrap-vue'
import store from '../../../../store'

Vue.use(VueViewer)

export default {
  components: {
    BFormCheckbox,
    BPagination,
    BSpinner,
    general,
    checkUp,
    obg,
  },
  props: {
    date: {
      type: String,
      default: () => '',
    },
    checkAllStatus: {
      type: undefined,
      default: () => '',
    },
    type: {
      type: String,
      default: () => 'EMR',
    },
    pic: {
      type: String,
      default: () => '',
    },
    picGroup: {
      type: Array,
      default: () => '',
    },
    group: {
      type: String,
      default: () => '',
    },
    hn: {
      type: undefined,
      default: () => '',
    },
    txn: {
      type: undefined,
      default: () => '',
    },
    index: {
      type: undefined,
      default: () => '',
    },
    // show: {
    //   type: Boolean,
    //   default: () => '',
    // },
  },
  data() {
    return {
      hieDetail: '',
      ipdIndex: '',
      token: '',
      windowTop: 0,
      currentPage: 1,
      perPage: 21,
      ipdPagination: 38,
      offSet: 0,
      amountDocument: '',
      picture: [],
      emrLocation: '',
      generalList: ['OP7ME1', 'OP7ME2', 'OP7EYE', 'MED13A', 'MRC'],
      checkupList: ['CHECKUP'],
      wellBabyList: ['WELBABY'],
      obList: ['OBG', 'OP7SUR'],
      imgConfig: {
        blank: false,
        blankColor: '#bbb',
        width: 200,
        height: 250,
        class: 'ml-1 mb-1',
        center: false,
      },
      mrImgConfig: {
        blank: false,
        blankColor: '#bbb',
        width: 400,
        height: 400,
        class: 'ml-1 mb-1',
        center: true,
      },
      count: false,
      otherSelected: [],
      mrSelected: [],
      ipdSelected: [],
      emrSelected: [],
      dataArray: [],
      otherDocAmount: '',
      options: {
        toolbar: true,
      },
      selectedItem: {
        MRs: [{
          imageName: '',
          location: '',
          group: 'mr',
          // type: '',
          date: '',
        }],
        EMRs: [{
          txn: '',
          location: '',
          group: 'emr',
          // type: '',
          date: '',
        }],
        Others: [{
          imageName: '',
          // location: '',
          group: 'other',
          type: '',
          date: '',
        }],
        IPDs: [{
          imageBase64: '',
          // location: '',
          group: 'ipd',
          date: '',
          // type: '',
        }],
      },
    }
  },
  // ตรวจสอบข้อมูล
  watch: {
    currentPage(val) {
      this.offSet = 21 * (val - 1)
      this.picture = []
      this.getIpdPic()
    },
    async otherSelected() {
      const otherData = [this.otherSelected, this.dataArray]
      await store.commit('hieStateImage/getOtherSelect', otherData)
    },
    async ipdSelected() {
      this.dataArray = [this.date, this.group]
      const ipdData = [this.ipdSelected, this.dataArray]
      await store.commit('hieStateImage/getIPDSelect', ipdData)
    },
  },
  async created() {
    this.token = localStorage.getItem('tokenFetchImage')
    if (this.type !== '') {
      if (this.type === 'MR' || this.type === 'Other') {
        await this.getPic()
        this.dataArray = [this.date, this.group]
      } else if (this.type === 'IPD') {
        await this.getAmountIpd()
        await this.getIpdPic()
      } else if (this.type === 'EMR') {
        // รอรวมหน้า
        this.dataArray = [this.txn]
        if (this.checkupList.includes(this.group)) {
          this.emrLocation = 'checkUp'
        } else if (this.wellBabyList.includes(this.group)) {
          this.emrLocation = 'wellBaby'
        } else if (this.obList.includes(this.group)) {
          this.emrLocation = 'obg'
        } else {
          this.emrLocation = 'general'
        }
      }
    }
  },
  async mounted() {
    let fromGetter = ''
    // สำหรับหารูป IPD
    this.hieDetail = store.getters['hieDetail/setHieTimeLine']
    this.ipdIndex = (this.hieDetail.find(ipdDate => ipdDate.date === this.date)).medicalDocument.ipd
    if (this.type === 'Other') {
      this.dataArray = [this.date, this.group]
      fromGetter = store.getters['hieStateImage/setOtherSelect'](this.dataArray)
    } else if (this.type === 'IPD') {
      this.dataArray = [this.date, this.group]
      fromGetter = store.getters['hieStateImage/setIpdSelect'](this.dataArray)
    }
    if (fromGetter && this.type === 'Other') {
      this.otherSelected = fromGetter
      this.count = true
    } else if (fromGetter && this.type === 'IPD') {
      this.ipdSelected = fromGetter
      this.count = true
    }
  },
  methods: {
    // หาจำนวนรูปIPD สำหรับคำนวณ
    checkItemOther() {
      if (this.otherSelected.length > 0 && this.count !== true) {
        this.count = true
        this.$emit('sendToCheckBoxUpdate', this.index, this.date, 'Other')
      } else if (this.otherSelected.length === 0 && this.count === true) {
        this.count = false
        this.$emit('spliceOtherDataUpdate', this.index, this.date)
      }
    },
    checkItemIPD() {
      if (this.ipdSelected.length > 0 && this.count !== true) {
        this.count = true
        this.$emit('sendToCheckBoxUpdate', this.index, this.date, 'IPD')
      } else if (this.ipdSelected.length === 0 && this.count === true) {
        this.count = false
        this.$emit('spliceIpdDataUpdate', this.index, this.date)
      }
    },
    async getAmountIpd() {
      const month = +this.date.substring(6, 7)
      const year = parseInt(this.date.substring(0, 4), 10)
      await this.$http({
        url: 'http://192.168.100.147/api/Profile/ClinicIPD',
        method: 'POST',
        data: {
          hn: this.hn,
          Year: year,
          Month: month,
        },
      }).then(({ data }) => {
        this.ipdPagination = data[0].amountDocument
      })
    },
    // หารูปของ Mr และ Other
    async getPic() {
      if (this.type === 'MR') {
        await this.getPicData('OPD', this.pic)
      } else {
        for (let index = 0; index < this.picGroup.length; index += 1) {
          this.getPicData('Grp', this.picGroup[index].imageBase64)
        }
        if (this.checkAllStatus === true) {
          await this.selectAll(this.type)
        }
        this.otherDocAmount = this.picGroup.length
      }
    },
    // getImageName(index) {
    //   const param = [this.date, index]
    //   return store.getters['hieDetail/setHieTimeLine'](param)
    // },
    async getIpdPic() {
      await this.$http({
        url: 'http://192.168.100.177/api/Profile/ImageIPD',
        method: 'POST',
        headers: { Authorization: `bearer ${localStorage.getItem('tokenFetchImage')}` },
        data: {
          Clinic: this.date,
          Month: null,
          Offset: this.offSet,
          Year: null,
          hn: this.hn,
        },
      }).then(({ data }) => {
        data.medicalRecord.forEach((element, index) => {
          this.picture.push({ ipdPic: `data:image/jpeg;base64, ${element.imageBase64}`, nameImage: this.ipdIndex[this.offSet + index].imageBase64 })
        })
      })
    },
    async getPicData(picType, picImage) {
      try {
        await this.$http({
          url: 'http://192.168.100.177/api/Profile/GetImage',
          method: 'POST',
          headers: { Authorization: `bearer ${localStorage.getItem('tokenFetchImage')}` },
          data: {
            Grp: this.group,
            IMage: picImage,
            Type: picType,
            hn: this.hn,
          },
        }).then(({ data }) => {
          this.picture.push(`data:image/jpeg;base64, ${data.imageBase64}`)
        })
      } catch (error) {
        // console.log(error)
      }
    },
    selectAll(type) {
      if (type === 'Other') {
        // เลือกทั้งหมดภายในวัน
        if (this.otherSelected.length !== this.otherDocAmount) {
          this.otherSelected = []
          for (let index = 0; index < this.otherDocAmount; index += 1) {
            this.otherSelected.push({
              date: this.date, type, group: this.group, nameImage: this.picGroup[index].imageBase64,
            })
          }
        // ลบทั้งหมดของวันที่เลือก
        } else {
          this.otherSelected = []
        }
        this.checkItemOther()
      } else if (type === 'IPD') {
        if (this.ipdSelected.length !== this.ipdPagination) {
          this.ipdSelected = []
          for (let listIpdIndex = 0; listIpdIndex < this.ipdPagination; listIpdIndex += 1) {
            this.ipdSelected.push({
              date: this.date, type, location: this.date, nameImage: this.ipdIndex[listIpdIndex].imageBase64,
            })
          }
        } else {
          this.ipdSelected = []
        }
        this.checkItemIPD()
      }
    },
  },
}
</script>

<style>
.yourContainer{
  width: auto !important;
  height: 50px !important;
  margin: 0 auto 1em auto; /*Center the image*/
}
/* .previewHeight{
  height: 500px;
  overflow: auto;
} */
</style>

<style lang='scss'>
</style>
